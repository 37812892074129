import React from 'react'
import './App.css'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './pages'
import Contact from './pages/contactUs'
import { Helmet } from 'react-helmet'

const App = () => {
  return (
    <BrowserRouter>
    <Helmet>
      <title>Accrescent Networks Inc.</title>
      <meta name='Description' content='Structured cabling|Data Center|Telecom Services'/>
      <meta name='keywords' content='Belden, Comscope, Siemon, Cisco, Leviton, All your cabling needs!! ' />
    </Helmet>
      <Routes>
           <Route path="/" element={<Home/>}  exact />
           <Route path="/contactus" element={<Contact/>} exact />
      </Routes>  
    </BrowserRouter>
  )
}

export default App
