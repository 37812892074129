import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import { Container, Form, FormButton, FormContent, Formh1, FormInput, FormLabel, FormWrap, Icon } from './ContactUsElements'

const ContactUs = () => {
  return ( 
    <>
    <Navbar/>
    <Container id="contactus">
        <FormWrap>  
            <FormContent>
                <Form method="POST" action="https://getform.io/f/2e73da6c-2c89-4d96-a772-e23d404a831a">
                    <Formh1>We'd Love to hear from you!</Formh1>
                    <FormLabel htmlFor='for'>Full Name</FormLabel>
                    <FormInput name='name' type='text' required/>
                    <FormLabel htmlFor='for'>Email</FormLabel>
                    <FormInput name='email' type='email' required/>
                    <FormLabel htmlFor='for'>Message</FormLabel>
                    <FormInput name='message' type='text' required/>
                    <FormButton type='submit'>Submit</FormButton>
                </Form>
            </FormContent>
        </FormWrap>
    </Container>
    <Footer />
    </>
  )
}

export default ContactUs