import React from 'react'
import ContactUs from '../components/ContactUs'
import ScrollToTop from '../components/ScrollToTop'

const Contact = () => {
  return (
    <div>
        <ScrollToTop />
        <ContactUs />
       
    </div>
  )
}

export default Contact