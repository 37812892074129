import React from 'react'
import {useState} from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/Data'
import Services from '../components/Services'
import Footer from '../components/Footer'
import Partners from '../components/Partners'

const Home = () => {
   const [isOpen, setIsOpen] = useState(false)
   
   const toggle = () => {
    setIsOpen(!isOpen)
   }

  return (
    <>
    
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}/>
    <HeroSection />
    <InfoSection {...homeObjOne}/>
    <InfoSection {...homeObjTwo}/>
    <Services/>
    <InfoSection {...homeObjThree}/>
    <Partners />
    <Footer/>
    
    </>
  )
}

export default Home